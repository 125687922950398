<template>
  <div class="p_20 indexBanner">
    <el-dialog
      :visible.sync="isShow"
      width="30%"
      :close-on-click-modal="false"
      @close="isShow = false"
    >
      <!-- <el-divider></el-divider> -->
      <h3 class="text_center title">派单</h3>
      <div class="text_center flex justify_content_center m_t_20 m_b_20">
        <table style="width: 100%; line-height: 30px" border cellspacing="0">
          <tr>
            <td width="50%">物流公司</td>
            <td>{{form.logistics.logistics_name}}</td>
          </tr>
          <tr>
            <td>物流单号</td>
            <td>{{form.logistics_odd}}</td>
          </tr>
          <tr>
            <td>持续时间</td>
            <td>{{form.last_time|format}} <br> {{form.first_time|format}}</td>
          </tr>
          <tr>
            <td>当前状态</td>
            <td>{{form.status}}</td>
          </tr>
          <tr>
            <td>问题</td>
            <td>{{form.remark}}</td>
          </tr>
          <tr>
            <td>详情</td>
            <td v-if="form.treatment_scheme.length">{{form.treatment_scheme[form.treatment_scheme.length-1].remark}}</td>
          </tr>
        </table>
      </div>

      <h3 class="text_center title">流转记录</h3>

      <div class="flex m_t_20 justify_content_around">
        <div style="min-height:400px;">
          <el-steps direction="vertical" space="270px" :active="1">
            <el-step slot-title="item.id"  v-for="item in order" :key="item.id"></el-step>
          </el-steps>
        </div>
        <div style="width:70%;" >
					<div v-for="item in order" :key="item.id" style="height:270px;">
						<div class="flex align_items_center">
							<div class="img">
								<img src="#" alt="">
							</div>
							<div class=" p_l_20">
								<span>{{item.admin ? item.admin.name : ''}}（123）</span>	
								<p>{{item.created_at|format}}</p>	
							</div>	
						</div>
						<div class=" m_t_10 p_10" style="background:#eee;height:100px;">
              {{item.remark}}
						</div>
            <div>

            </div>
					</div>
				</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,

      form: {
        logistics:{name:''},
        treatment_scheme:[{remark:''}]
      },
      upImg: {
        label: "图片",
        imageUrl: "",
        name: "file",
        width: "1080",
        height: "768",
        file: "",
      },
      order:[],

      //图片上传
      imageUrl: "",
      // dialogVisible: false,
      // disabled: false
    };
  },
  methods: {
    //提交
    getOrder() {
      // let a = JSON.parse(localStorage.getItem(this.sy)).group_id;
      let params = {
        logistics_odd:this.form.logistics_odd,
        // group_id:a,
        // client_id:this.form.client_id
      }
      this.axiosPost('SendOrders/list' ,  params , res => {
        // console.log(res);
        if(res.data.code === 200) {
          this.order = res.data.data;
        }
      })
    },

    
  },
};
</script>

<style scoped>
.title {
  background: #66b1ff;
  margin: 0;
  padding: 10px 0px;
  color: #fff;
}

.img {
	border:1px solid #000;
	width: 60px;
	height: 60px;
	border-radius: 50% ;
}
.el-step:last-child {
	flex-basis: 0 !important;
}
</style>
